<template>
    <div class="d-flex w-100">
        <div style="width: calc(100% - 420px);">
            <dag-demo ref="dagDemo" @changeState="handleChangeDagAnimationState" :style="modal
                ? `height: calc(100vh - 160px)`
                : `height: calc(100vh - 137px)`
                "></dag-demo>
        </div>

        <div class="borderLeft" style="width: 420px;">
            <b-card-body>
                <b-card-title>
                    {{ detail.eqname }}
                    {{ detail.function_name }}
                </b-card-title>
                <b-card-sub-title>
                    <b-card-text class="mb-25">
                        <b-icon-clock-fill class="mr-25"></b-icon-clock-fill>
                        时间
                        <span class="float-right">{{
                            detail.create_date
                            }}</span>
                    </b-card-text>
                    <b-card-text class="mb-25">
                        <b-icon-hdd-fill class="mr-25"></b-icon-hdd-fill>
                        设备
                        <span class="float-right">{{ detail.eqname }} </span>
                    </b-card-text>
                    <b-card-text class="mb-25">
                        <b-icon-bell-fill class="mr-25"></b-icon-bell-fill>
                        等级
                        <span class="float-right">{{ detail.degree }} </span>
                    </b-card-text>
                    <b-card-text class="mb-25">
                        <b-icon-tag-fill class="mr-25"></b-icon-tag-fill>
                        类型
                        <span class="float-right">{{ detail.type }} </span>
                    </b-card-text>
                </b-card-sub-title>

                <div id="canvas" style="height: 10rem" class="mt-2"></div>
            </b-card-body>

            <hr class="my-0" />

            <b-card-body>
                <b-row>
                    <b-col cols="6">
                        <b-card bg-variant="light-warning">
                            风险数值
                            <div class="d-flex align-items-center justify-content-between mt-50">
                                <h2 class="mb-0">70%</h2>
                                <b-badge variant="light-warning">10:12:35</b-badge>
                            </div>
                        </b-card>
                    </b-col>
                    <b-col cols="6">
                        <b-card bg-variant="light-success">
                            安全范围
                            <h2 class="mt-50 mb-0">30% ~ 60%</h2>
                        </b-card>
                    </b-col>
                </b-row>

                <b-button :disabled="animationState" @click="handleClickPlayAnimation" variant="primary" block>
                    <b-icon-play-btn-fill class="mr-50" />
                    演示推理过程
                </b-button>
            </b-card-body>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import DagDemo from "@/views/front/common/agent-list/components/DagDemo.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { fault_detail } from "@/api/diagnosis";
import { Area } from "@antv/g2plot";
import { showToast } from "@/libs/utils/showToast";

export default {
    name: "AgentDetailRight",
    props: {
        id: {
            type: Number,
        },
        modal: {
            default: false,
        },
    },
    components: {
        AppCollapse,
        DagDemo,
        AppCollapseItem,
        VuePerfectScrollbar,
    },
    data: () => ({
        detail: {
            create_date: "",
            system: "",
            degree: "",
            function_name: "",
            fault_name: "",
            fault_reason: "",
            eqname: "",
        },
        areaPlot: null,
        animationState: true,
    }),

    methods: {
        handleChangeDagAnimationState(v) {
            this.animationState = v;
        },
        handleClickPlayAnimation() {
            this.$refs.dagDemo.innerManualPlayAnimation();
        },
        initareaPlot(chartData) {
            if (!chartData) return;
            if (this.areaPlot) {
                this.areaPlot.data = chartData;
                this.areaPlot.update();
                return;
            }
            this.areaPlot = new Area("canvas", {
                data: chartData,
                xField: "time",
                yField: "value",
                xAxis: {
                    grid: null,
                },
                yAxis: {
                    grid: null,
                },
                legend: null,
                animate: true,
                animation: {
                    appear: {
                        animation: "wave-in",
                        duration: 10000,
                    },
                },
            });

            this.areaPlot.render();
        },
        update(data) {
            this.detail = data;
        },
    },

    mounted() {
        // scroll_active("#project-detail-operation-scroll-notice");
        // initareaPlot();
        if ((!this.$route.params.detail && this.id) || this.modal) {
            const id = this.id ? this.id : this.$route.params.id;
            fault_detail(id).then((res) => {
                showToast(null, "错误", "获取数据错误,未能够获取到数据");
                if (!res) return;
                this.detail = res.data;
                const data = JSON.parse(this.detail.data);
                const chartData = [];
                for (let i in data.time) {
                    const d = new Date(data.time[i]);
                    chartData.push({
                        time: `${d.getFullYear()}-${d.getMonth() + 1 < 10
                            ? "0" + (d.getMonth() + 1)
                            : d.getMonth() + 1
                            }-${d.getDate() + 1 < 10
                                ? "0" + (d.getDate() + 1)
                                : d.getDate() + 1
                            } ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`,
                        pvvarname: data.pvvarname[i],
                        value: data.value[i],
                    });
                }
                this.detail.chartData = chartData;
                this.initareaPlot(this?.detail?.chartData);
            });
        } else if (this.$route.params.detail) {
            this.detail = this.$route.params.detail;
            this.initareaPlot(this?.detail?.chartData);
        }
    },
};
</script>
